.Services {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: auto auto auto auto;
    /*max-height:calc(100vh - 132px);*/
    justify-content: center;
    overflow: auto;
    /*background-color: rgb(208, 235, 166);*/
    margin-top: 2rem;
    padding: 0;
    height: 80%;
  }

  .Service {
    position: relative;
    display: block;
    width: 16rem;
    height: auto;
    border-style: solid;
    border-width: 1px 5px 5px 1px; /* top, right, bottom and left */
    border-color: #047baa;
    border-radius: 0;
    padding: 1rem;
    margin: 1rem;
    text-decoration: none;
    color: rgb(16, 35, 74);
    background-color: transparent;
    transition: 0.3s;
    font-size: 1rem;
    outline: none;
    user-select: none;

/*    
    pointer-events: none;
    cursor: not-allowed;
*/
 }

  .Service:hover {
      color: rgb(47, 124, 148);
      background-color: #b3cbe4; /* Silver Blue; */
  }

  .Service img {
    position: relative;
    width: 100%;
  }
  
  .ServiceName {
    width: 100%;
    text-align: center;
  }

  .ServiceInfo {
    width: 100%;
    color: darkblue;

  }

  .ButtonShadow {
    padding: 15px 25px;
    font-size: 2em;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: #fff;
    background-color: #047baa;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
  }
  
  .ButtonShadow:hover {background-color: #3e8e41}
  
  .ButtonShadow:active {
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }