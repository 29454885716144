
.App {
  text-align: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-image: linear-gradient(to bottom right, #5D88BB, silver, lightblue)
}

.navbar-right {
  float: right;
}

.Header {
  top: 0;
  position: relative;
  height: 120px;
  width: 100%;
  /*left:5%;*/
 }

 .Navigation {
  top: 10px;
  position: relative;
 }

.Logo {
  border-radius: 50%;
}


@media (prefers-reduced-motion: no-preference) {
  .Logo {
    animation: Logo-spin infinite 20s linear;
  }
}

@media (orientation: portrait) { 
  .Services {
    top:3rem;
    grid-template-columns: repeat(3, 1fr)!important;
    height: max-content;
  }
  @media (max-width: 600px) {
    .Services {
      grid-template-columns: repeat(1, 1fr)!important;
    }
  }
}


@keyframes Logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

 /* Now set the height of your Footer equal to the padding that you left for it in your #root.  To make it stay at the bottom, you will set it to be absolutely positioned.  If Main grows larger, your Footer will move down to remain at the bottom of the page. */ 
 .Footer {
  position: fixed;
  margin: 0;
   height: 32px;
   width: 100%;
   bottom: 0;
   font-size: 12px;
   text-align: center;
   vertical-align: middle;
   color: rgba(0,158,213,255); /* rgba(51,73,138,255); */
   background-color: transparent; 
 }



/* 220405 Tab Container */
.Tabs {
  width: 80%;
  justify-content: center;
  height: auto;
  min-height: 40px;
  background: #053742;
  margin: 2rem auto 2rem;
  padding: 1rem;
  color: #E8F0F2;
  border-radius: 1rem;
  @media (max-width: 769px) {
    padding: 2rem 0;
  }

}

/* Tab Navigation */
ul.nav {
  width: 90%;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #39A2DB;
  border-radius: 2rem;
  @media (max-width: 768px) {
    width: 90%;
  }
}

ul.nav li {
  width: 33%;
  padding: 1rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
  border-radius: 2rem;
}


/*
ul.nav li:nth-child(2) {
  border-radius: 0;
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
}
*/
ul.nav li:hover {
  background: rgba(50, 224, 196, 0.15);
}
ul.nav li.active {
  background: rgba(0,158,213,255);
}

.SignBasic p,
.SignEmail p,
.SignPhone p {
  font-size: 2rem;
  text-align: center;
}
