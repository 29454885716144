body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /*background-color: rgba(51,73,138,255);*/
  /*background-color: rgba(0,158,213,255);*/
  background-color: rgba(250,250,250,255);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

thead {
  background-color: rgba(51,73,138,255);  /*#33498A*/
  color:azure;
}

th {
  vertical-align: middle;
  /*justify-content: center;*/
}

th:hover {
  background-color: rgba(0,158,213,255);
}

.navbar-custom {
  background-color: rgba(51,73,138,255);
  color:rgba(0,158,213,255);
  font-weight: bold;
  font-size: 1.5rem;
}

.NavLink {
  text-align: center;
}

nav a:hover {
  background-color: rgba(0,158,213,255);
  color: rgba(51,73,138,255);
}

.degrade-diagonal-1 {
  background: linear-gradient(to bottom right, rgba(51,73,138,150), rgba(0,158,213,150));
}

.degrade-diagonal-2 {
  background: linear-gradient(to bottom right, rgba(0,158,213,150), rgba(250,250,250,150));
}

.degrade-diagonal {
  background: linear-gradient(to bottom right, rgba(250,250,250,150), rgba(0,158,213,150));
}

.btn-close {
  background: none;
  border: 0;
  font-weight: bolder;
  color: #dc3545!important;  

}

.btn-close:after {
  /*content: "\2705";*/
  content: "\2573";
  position: relative;
  /* top: -3px; */
}

/*
.btn-close {
  background:  transparent url('https://picsum.photos/25/25');
}
*/